<main>
    <header>
        <img src="../../assets/logo.png" routerLink="/">

        <a routerLink="/terms"> Termos </a> 
    </header>
    
    <section>
        <div class="card_left">
            
            <h1> Juntos somos <span>+1</span> </h1>

            <h3>
                Junte-se com seus amigos e chame muito mais atenção! O +1 sincroniza mídias de diferentes aparelhos, somando
                um ao outro e amplificando todos. Faça uma festa com a música muito mais alta; ou um mosaico de torcida
                muito maior; ou faça um cartaz digital pra sua manifestação. Com +1 criamos um sentimento de comunidade
                global. vem ser +1 e some com a gente
            </h3>
            
            <div class="card_row">
                <img src="../../assets/pt-br_badge_web_generic.png" alt="">
                <img src="../../assets/appstore.png" alt="">
            </div>
        </div>
        
        <div class="card_right">
            <img src="../../assets/cell.png" alt="">
        </div>

    </section>

    <div class="circle"></div>
    
    <footer>
        <div (click)="scrollToElement('footer')" class="text_top">
            <mat-icon>keyboard_arrow_up</mat-icon>
            <p> Apresentação do App </p>
        </div>
        
        <iframe src="https://www.youtube.com/embed/MmATDq1lZVU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </footer>
    
    <div id="footer"></div>
</main>